import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { i18n } from '../../next-i18next.config'
import { PageWrapper } from '../components/common/styled'
import { fetchForceAppless } from '../lib/api/market'
import { formatServerSideTranslations } from '../lib/i18n'
import styles from '../styles/Home.module.css'

const FourOFour: NextPage = () => {
  const router = useRouter()
  return (
    <PageWrapper className={styles.container}>
      <Head>
        <title>404</title>
        <meta name="description" content="Brick Technology 400 page, Web" />
      </Head>

      <main className={styles.main}>
        <code className={styles.code}>
          {router.route}, {router.locale}
        </code>
        <h1 className={styles.title}>{"Page doesn't exist"}</h1>
      </main>
    </PageWrapper>
  )
}

export const getStaticProps: GetStaticProps = async ({
  locale = i18n.defaultLocale,
}) => ({
  props: {
    ...(await formatServerSideTranslations(locale, ['common', 'navigation'])),
    applessOnly: await fetchForceAppless(locale),
  },
})

export default FourOFour
